import {IHostProps} from 'native-components-infra/dist/src/types/types'
import {MemberAppProps} from '../../interface'
import {connect} from '../connect'
import {AppLoaded as AppLoadedUnconnected} from './app-loaded'

export interface AppLoadedProps extends AppLoadedOwnProps {
  appLoaded: () => void
}

export interface AppLoadedOwnProps {
  host: IHostProps
}

const mapRuntime = ({actions}: MemberAppProps) => ({
  appLoaded: actions.appLoaded,
})

export const AppLoaded = connect<AppLoadedOwnProps>(mapRuntime)(AppLoadedUnconnected)
